<template lang="pug">
  v-container
    v-row
      v-col(cols="12")
        v-item-group(
          v-model="type"
          mandatory
        )
          v-row(no-gutters)
            v-col(cols="6")
              v-item(
                v-slot="{ toggle, active }"
                value="cash"
              )
                v-btn(
                  :color="active ? 'green' : 'blue-grey darken-3'"
                  dense
                  block
                  tile
                  dark
                  elevation="0"
                  :text="!active"
                  @click="toggle"
                ) CASH
            v-col(cols="6")
              v-item(
                v-slot="{ toggle, active }"
                value="cheque"
              )
                v-btn(
                  :color="active ? 'blue' : 'blue-grey darken-3'"
                  dense
                  dark
                  tile
                  elevation="0"
                  block
                  :text="!active"
                  @click="toggle"
                ) CHEQUE
        v-divider
      v-col(cols="12")
        v-switch(
          inset
          label="Returned"
          hide-details
          v-model="returned"
        )
      v-col(cols="12" v-if="type === 'cheque'")
        v-text-field(
          label="Cheque Number"
          dense
          v-model="chequeNumber"
          hide-details="auto"
          :error-messages="voucherTransactionPostErrors.cheque_number"
        )
      v-col(cols="12")
        v-text-field(
          label="Amount"
          type="number"
          dense
          v-model.number="amount"
          hide-details="auto"
          :error-messages="voucherTransactionPostErrors.amount"
        )
      v-col(cols="12")
        v-text-field(
          label="Date"
          type="date"
          dense
          v-model="date"
          hide-details="auto"
          :error-messages="voucherTransactionPostErrors.date"
        )
      v-col(cols="12")
        v-text-field(
          v-if="!editMode"
          label="Status"
          disabled
          dense
          hide-details="auto"
          :value="this.statusDescription"
        )
        v-autocomplete(
          v-else
          label="Status"
          dense
          v-model="voucherChequeStatusId"
          :error-messages="voucherTransactionPostErrors.voucher_cheque_status_id"
          :items="voucherChequeStatusIndexList"
          item-value="id"
          item-text="description"
          hide-details="auto"
        )
      v-col(cols="12" v-if="!editMode")
        v-btn(
          dense
          block
          @click="store()"
          color="primary"
          :loading="voucherTransactionPosting"
        )
          span Save
      v-col(cols="12" v-else)
        v-row
          v-col(cols="6")
            v-btn(
              color="yellow darken-2"
              dark
              dense
              small
              block
              @click="update()"
              :loading="voucherTransactionPosting"
            )
              span Update
          v-col(cols="6")
            v-btn(
              color="red"
              dark
              dense
              small
              block
              @click="editMode = false"
            )
              span Cancel
</template>
<script>
import voucherChequeStatusRepository from '@/repositories/voucher-cheque-status.repository'
import voucherTransactionRepository from '@/repositories/voucher-transaction.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [voucherChequeStatusIndexVars, voucherChequeStatusIndexVarNames] = requestVars({ identifier: 'voucher-cheque-status-index' })
const [voucherTransactionStoreVars, voucherTransactionStoreVarNames] = requestVars({ identifier: 'voucher-transaction', request: 'post', hasData: false })

const voucherChequeStatusIndexHandler = new VueRequestHandler(null, voucherChequeStatusIndexVarNames)
const voucherTransactionStoreHandler = new VueRequestHandler(null, voucherTransactionStoreVarNames)

const inputVars = () => ({
  id: null,
  returned: false,
  type: null, 
  chequeNumber: process.env.NODE_ENV === 'production' ? null : new Date().getTime(),
  amount: null,
  voucherChequeStatusId: 'NW',
  date: Window.getCurrentDate(),
})

export default {
  name: 'CreateVoucherTransaction',
  props: ['voucherId', 'edit', 'voucherAmount'],
  data () {
    return {
      ...voucherChequeStatusIndexVars,
      ...voucherTransactionStoreVars,
      ...inputVars(),
    }
  },
  created () {
    this.getVoucherChequeIndex()
  },
  computed: {
    statusDescription () {
      const status = this.voucherChequeStatusIndexList.find(item => item.id === this.voucherChequeStatusId)
      return status ? status.description : null
    },
    editMode: {
      get () {
        this.popuplateInputVars(this.edit)
        return !this.$objectEmpty(this.edit)
      },
      set (val) {
        if (val) return
        this.reset()
        this.voucherTransactionPostErrors = {}
        this.$emit('update:edit', {})
      },
    },
  },
  methods: {
    getVoucherChequeIndex () {
      const handler = voucherChequeStatusIndexHandler
      const repository = voucherChequeStatusRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    popuplateInputVars (data) {
      Object.keys(data)
        .forEach(key => {
          if (key === 'amount') this.returned = data[key] < 0
          const camelKey = key.snakeToCamelCase()
          this[camelKey] = (isNaN(data[key]) && data[key]) || Math.abs(data[key]) || null
        })
    },
    update () {
      const conf = { title: 'Warning', icon: 'mdi-alert', color: 'yellow darken-2' }
      this.$confirm('Once status has been updated this item will no longer be editable', conf)
        .then(confirm => {
          if (!confirm) return
          const handler = voucherTransactionStoreHandler
          const repository = voucherTransactionRepository.update
          const { id, returned, ...data } = this.getInputData()
          if (returned) {
            data.amount *= -1
          }
          handler.setVue(this)
          handler.execute(repository, [id, data], () => { this.editMode = false })
        })
    },
    store () {
      const handler = voucherTransactionStoreHandler
      const repository = voucherTransactionRepository.store
      const { id, returned, ...data } = this.getInputData()
      data.amount *= !returned || -1
      handler.setVue(this)
      handler.execute(repository, [data], this.reset)
    },
    reset () {
      const defaults = inputVars()
      Object.keys(defaults)
        .forEach(key => {
          this[key] = defaults[key]
        })
    },
    getInputData () {
      return Object.keys(inputVars())
        .concat(['voucherId'])
        .reduce((result, key) => {
          const snakeKey = key.camelToSnakeCase()
          result[snakeKey] = this[key]
          return result
        }, {})
    },
  },
}
</script>